import type { AppProps } from 'next/app';
import { NextIntlProvider } from 'next-intl';
import { DefaultSeo, NextSeo } from 'next-seo';

import { onError, getMessageFallback } from '@/i18n/utils';
import { inter, matter, matterMono } from '@/lib/fonts';

import { HOST } from '@/lib/host';

import Layout, { Settings } from '@/components/layout';

import '@fu/ui/global.css';
import '@/styles/globals.css';

type FUAppProps = AppProps<{
  messages: IntlMessages;
  now: number;
  settings: Settings;
}>;

export default function FUApp({ Component, pageProps }: FUAppProps) {
  return (
    <>
      <style jsx global>{`
        :root {
          --font-inter: ${inter.style.fontFamily};
          --font-matter: ${matter.style.fontFamily};
          --font-matter-mono: ${matterMono.style.fontFamily};
        }
      `}</style>

      <DefaultSeo
        title="Folkeuniversitetet"
        description="Folkeuniversitetet - Kveldskurs, dagskurs, nettstudier. Finn et kurs som passer til deg!"
        themeColor="#fff"
        openGraph={{
          type: 'website',
          locale: 'nb_NO',
          url: HOST,
          siteName: 'Folkeuniversitetet',
          // title: '',
          // description: '',
          // images: [],
          // videos: [],
        }}
        // facebook={{ appId: '' }}
        twitter={{
          handle: '@Folkeunivers',
          site: '@site',
          cardType: 'summary_large_image',
        }}
      />

      {/* TODO: REMOVE THIS WHEN WE GO LIVE */}
      <NextSeo nofollow noindex />

      <NextIntlProvider
        messages={pageProps.messages}
        onError={onError}
        getMessageFallback={getMessageFallback}
        now={new Date(pageProps.now)}
        timeZone="Europe/Oslo"
      >
        <Layout settings={pageProps.settings}>
          <Component {...pageProps} />
        </Layout>
      </NextIntlProvider>
    </>
  );
}
