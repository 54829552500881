import { Suspense } from 'react';
import dynamic from 'next/dynamic';

import SkipLink from '@/components/skip-link';
import type { FooterQueryResults } from '@/components/footer';
import type { MenuQueryResults } from '@/components/menu';

const Header = dynamic(() => import('@/components/header'), {
  suspense: true,
});

const Footer = dynamic(() => import('@/components/footer'), {
  suspense: true,
});

const Toaster = dynamic(() => import('@/components/toaster'), {
  suspense: true,
});

export interface Settings {
  footer?: FooterQueryResults;
  menu?: MenuQueryResults;
}

export type LayoutProps = {
  settings: Settings;
  children: React.ReactNode;
};

export default function Layout({ settings, children }: LayoutProps) {
  return (
    <div className="flex min-h-screen flex-col">
      <SkipLink />

      {settings?.menu ? (
        <Suspense fallback={null}>
          <Header {...settings.menu} />
        </Suspense>
      ) : (
        <header className="bg-neutral p-20 text-white">
          <p>
            OBS! Header query ikke kjørt, ingen data å presentere! Bruk
            queryBuilder() til å hente ut sideinnhold.
          </p>
        </header>
      )}
      {children}
      {settings?.footer ? (
        <Suspense fallback={null}>
          <Footer {...settings.footer} />
        </Suspense>
      ) : (
        <footer className="bg-neutral p-20 text-white">
          <p>
            OBS! Footer query ikke kjørt, ingen data å presentere! Bruk
            queryBuilder() til å hente ut sideinnhold.
          </p>
        </footer>
      )}
      <Suspense fallback={null}>
        <Toaster />
      </Suspense>
    </div>
  );
}
