import { IntlErrorCode } from 'next-intl';
import type { IntlError } from 'next-intl';

export function onError(err: IntlError) {
  if (err.code === IntlErrorCode.MISSING_MESSAGE) {
    // Missing translations are expected and should only log an error
    console.error(err);
  } else {
    // Other errors indicate a bug in the app and should be reported
    // E.g. sent to sentry
    console.error(err);
  }
}

export function getMessageFallback({
  namespace,
  key,
  error,
}: {
  namespace?: string | undefined;
  key: string;
  error: IntlError;
}) {
  const path = [namespace, key].filter(Boolean).join('.');

  if (error.code === IntlErrorCode.MISSING_MESSAGE) {
    return `${path} is not yet translated`;
  } else {
    return `Dear developer, please fix this message: ${path}`;
  }
}
