import clsx from 'clsx';
import { useTranslations } from 'next-intl';

export function SkipLink() {
  const t = useTranslations();
  return (
    <a
      className={clsx(
        'block',
        'fixed top-0 left-1/2',
        'p-3',
        'z-40',
        'rounded-b',
        'bg-neutral',
        'text-white',
        '-translate-x-1/2 -translate-y-full',
        'focus:translate-y-0',
        'transition'
      )}
      href="#main"
    >
      {t('skipToMain')}
    </a>
  );
}

export default SkipLink;
